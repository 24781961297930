import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import { useMutation } from '@apollo/react-hooks';
import { SignUp_Mutation } from '@netfront/gelada-react-shared';
import { useRouter } from 'next/router';
import { Input, Label } from 'components/Form';

export function RegisterForm() {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [groupCode, setGroupCode] = useState('');

  const [createUser, { loading: isLoading, error }] = useMutation(SignUp_Mutation, {
    context: { clientName: 'auth' },
    onCompleted: () => {
      router.push('/register-success');
    },
  });

  const sendRequest = (e: any) => {
    e.preventDefault();

    const incompleteRegister = !firstname || !lastname || !groupCode || !email || !password || !passwordConfirm || !groupCode;

    if (incompleteRegister) return;

    createUser({
      variables: {
        user: { firstname, lastname, groupCode: +groupCode },
        credential: { email, password },
      },
    })
      // do nothing with the error as we use the message from the mutation
      .catch(() => {});
  };

  const lazyRequest = { sendRequest, isLoading, error };

  return (
    <AuthenticationForm ctaName="Login" lazyRequest={lazyRequest}>
      <Label htmlFor="email">Email:</Label>
      <Input id="email" type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} required />
      <Label htmlFor="password">Password:</Label>
      <Input id="password" type="password" value={password} onChange={({ target: { value } }) => setPassword(value)} required />
      <Label htmlFor="passwordconfirm">Password confirm:</Label>
      <Input id="passwordconfirm" type="password" value={password} onChange={({ target: { value } }) => setPasswordConfirm(value)} required />

      <Label htmlFor="firstname">First name:</Label>
      <Input id="firstname" type="text" value={firstname} onChange={({ target: { value } }) => setFirstname(value)} required />
      <Label htmlFor="lastname">Last name:</Label>
      <Input id="lastname" type="text" value={lastname} onChange={({ target: { value } }) => setLastname(value)} required />
      <Label htmlFor="groupCode">Group code:</Label>
      <Input id="groupCode" type="number" value={groupCode} onChange={({ target: { value } }) => setGroupCode(value)} required />
    </AuthenticationForm>
  );
}
