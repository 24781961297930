import React from 'react';
import cx from 'classnames';
import Card, { CardContent } from 'components/Card';

import styles from './AuthenticationLayout.module.css';

export function AuthenticationLayout({ heading, children }) {
  return (
    <div className={styles['AuthenticationLayout']}>
      <h1 className={cx('light', styles['Heading'])}>{heading}</h1>
      <Card className={styles['Card']}>
        <CardContent className={styles['Card--content']}>{children}</CardContent>
      </Card>
    </div>
  );
}
