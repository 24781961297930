import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useIsAuthenticated } from 'lib/hooks/useIsAuthenticated';
import styles from './Layout.module.css';
import SEO from 'components/SEO';

interface LayoutProps {
  title: string;
  description?: string;
  children: React.ReactNode;
}

export function Layout({ title, description, children }: LayoutProps) {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <SEO title={title} description={description} />
      <main className={styles['main']}>
        <div className={styles['Layout']}>
          <Header showMenu={isAuthenticated} />
          <div className={styles['layout--internal']}>{children}</div>
        </div>
        <Footer />
      </main>
    </>
  );
}
