import React, { useEffect } from 'react';
import styles from './AuthenticationForm.module.css';
import Button from 'components/Button';
import { toast } from 'react-toastify';

export function AuthenticationForm({ children, ctaName, lazyRequest: { sendRequest, isLoading, error } }: any) {
  useEffect(() => {
    const errorMessage = error?.message?.replace(/GraphQL error:/g, '');
    if (errorMessage) {
      toast.error(errorMessage || 'Sorry, something went wrong', { position: 'bottom-center' });
    }
  }, [error]);

  return (
    <form onSubmit={sendRequest}>
      {children}
      <Button className={styles['submit']} type="submit" style="rounded" disabled={isLoading}>
        {ctaName}
      </Button>
    </form>
  );
}
