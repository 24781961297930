import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import { useMutation } from '@apollo/react-hooks';

import { Input, Label, Textarea } from 'components/Form';

import client from 'lib/HowlerClient';
import gql from 'graphql-tag';

const contactMutation = gql`
  mutation ContactMutation(
    $sender: String!
    $lastname: String!
    $firstname: String!
    $message: String
  ) {
    notification {
      sendContactForm(
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
        sender: $sender
        lastname: $lastname
        firstname: $firstname
        message: $message
      )
    }
  }
`;

export function ContactForm() {
  const [successful, setSuccessful] = useState(false);

  const [sendContactMutation, { loading: isLoading, error }] = useMutation(contactMutation, {
    client: client(),
  });

  const [firstname, setFirstname] = useState<any>('');
  const [lastname, setLastname] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [message, setMessage] = useState<any>('');

  const sendRequest = (e: any) => {
    e.preventDefault();
    sendContactMutation({
      variables: {
        sender: email,
        firstname,
        lastname,
        message,
      },
    }).then(() => {
      setSuccessful(true);
    });
  };

  const lazyRequest = { sendRequest, isLoading, error };

  if (successful) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
        <p>Your message has successfully set.</p>
        <p>Thank you for contacting us.</p>
      </div>
    );
  }

  return (
    <AuthenticationForm ctaName="Send" lazyRequest={lazyRequest}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '1rem', width: '100%' }}>
          <Label htmlFor="firstname">First Name:</Label>
          <Input id="firstname" value={firstname} onChange={({ target: { value } }) => setFirstname(value)} required />
        </div>
        <div style={{ width: '100%' }}>
          <Label htmlFor="lastname">Last Name:</Label>
          <Input id="lastname" value={lastname} onChange={({ target: { value } }) => setLastname(value)} required />
        </div>
      </div>
      <Label htmlFor="email">Email:</Label>
      <Input id="email" type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} required />
      <Label htmlFor="message">Message:</Label>
      <Textarea id="message" value={message} onChange={({ target: { value } }) => setMessage(value)} required />
    </AuthenticationForm>
  );
}
