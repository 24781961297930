import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import { useLazyQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { Login_Query, _StoreTokens, _Store, _eStorageKey } from '@netfront/gelada-react-shared';
import gtag from 'ga-gtag';

import { Input, Label } from 'components/Form';
import Link from 'components/Link';
import styles from './LoginForm.module.css';

export function LoginForm() {
  const router = useRouter();

  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState<any>('');

  const [loginUser, { loading: isLoading, error }] = useLazyQuery(Login_Query, {
    context: { clientName: 'auth' },
    onCompleted: (data) => {
      const token = data?.login?.token;
      if (token) {
        gtag('set', { user_id: data.login.user.id });
        _Store(data.login.user, _eStorageKey.userData);
        _StoreTokens(token, data.login.refreshToken);
        router.push(router?.query?.prepath?.toString?.() || '/dashboard');
      }
    },
  });
  const sendRequest = (e: any) => {
    e.preventDefault();
    if (!email || !password) return;
    loginUser({ variables: { login: email, password: password } });
  };

  const lazyRequest = { sendRequest, isLoading, error };

  return (
    <AuthenticationForm ctaName="Login" lazyRequest={lazyRequest}>
      <Label htmlFor="email">Email:</Label>
      <Input required id="email" type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} />
      <Label htmlFor="password">Password:</Label>
      <Input required id="password" type="password" value={password} onChange={({ target: { value } }) => setPassword(value)} />
      <div className={styles['Login--form--link']}>{/* <Link href="/forgot-password">Forgot password?</Link> */}</div>
    </AuthenticationForm>
  );
}
